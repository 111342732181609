@keyframes bounceAndGlow {
  from { opacity: 1; transform: translateY(0);}
  to { opacity: .8; transform: translateY(-30%);}
}

.mapboxgl-map {
  width: 100%;
  /* height: calc(100vh - 64px); */
  height: 100vh;
}

.mapboxgl-ctrl-geocoder {
  width: 350px;
  max-width: none;
}

.mapbox-gl-download-btn {
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: auto;
  background-size: 22px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgeD0iMHB4IiB5PSIwcHgiPjx0aXRsZT5kb3dubG9hZF8xPC90aXRsZT48cGF0aCBkPSJNMTIsMTZhMSwxLDAsMCwxLS43MS0uMjlsLTQtNGExLDEsMCwwLDEsMS40MS0xLjQxTDEyLDEzLjU5bDMuMjktMy4yOWExLDEsMCwwLDEsMS40MSwxLjQxbC00LDRBMSwxLDAsMCwxLDEyLDE2WiI+PC9wYXRoPjxwYXRoIGQ9Ik0xMiwxNmExLDEsMCwwLDEtMS0xVjVhMSwxLDAsMCwxLDIsMFYxNUExLDEsMCwwLDEsMTIsMTZaIj48L3BhdGg+PHBhdGggZD0iTTE5LDIwSDVhMSwxLDAsMCwxLTEtMVYxNWExLDEsMCwwLDEsMiwwdjNIMThWMTVhMSwxLDAsMCwxLDIsMHY0QTEsMSwwLDAsMSwxOSwyMFoiPjwvcGF0aD48L3N2Zz4=);
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group,
.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-geocoder--input {
  /* background: #595959; */
  background: #1c1717;
  color: white;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.mapboxgl-ctrl-group button {
  filter: invert(1);
}

.mapboxgl-ctrl-group button+button {
  border: none;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  margin-right: 24px;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  margin: 0;
  background: none;
}

.mapboxgl-ctrl-attrib a {
  color: rgba(255,255,255,0.2);
}

.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-in,
.mapboxgl-ctrl-icon.mapboxgl-ctrl-zoom-out,
.mapboxgl-ctrl-icon.mapboxgl-ctrl-geolocate::before {
  filter: invert(1);
}

.mapboxgl-ctrl-bottom-right {
  z-index: 1;
}


/* The Big City Selector input */

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -300px;
  margin-top: -60px;
  font-size: 24px;
  padding: 12px 16px;
  width: 600px;
  max-width: none;
  z-index: 3;
  /* box-shadow: 0 16px 50px 0px rgba(0, 0, 0, 0.3), 0 10px 7px -8px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 16px 50px 0px rgba(250, 250, 250, 0.1), 0 10px 7px -8px rgba(250, 250, 250, 0.5);

  visibility: hidden;
}

body.show-city-picker .mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder {
  visibility: visible !important;
}

/* .mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder input {
  padding: 0;
} */

.mapboxgl-ctrl-top-left svg {
  width: 32px;
  height: 32px;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder--icon-loading {
  top: 16px;
  right: 16px;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 6px;
  margin-right: 6px;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder--icon-search {
  /* display: none; */
  top: 15px;
  left: 12px;
  opacity: .3;
}

.mapboxgl-ctrl-logo {
  opacity: .2;
}


/* Mobile */

@media (min-width: 320px) and (max-width: 480px) {
    .mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder {
      width: 90%;
      margin-left: -45%;
      font-size: 16px;
      padding: inherit;
    }

    .mapboxgl-ctrl-top-left .mapboxgl-ctrl-geocoder--icon-search {
      top: 15px;
      left: 12px;
      width: 22px;
      height: 22px;
    }

    .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
      margin-right: 12px;
    }
}