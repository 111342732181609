/* Mobile */

@media (min-width: 320px) and (max-width: 480px) {
    .layers-panel {
        left: 0;
        font-size: 8px !important;
        display: none;
    }
    
    .layer-name {
        font-weight: 300;
    }

    .layer-length {
        display: none;
    }

    .ant-tooltip,
    .ant-popover-inner-content,
    .layer-row .ant-switch {
        display: none;
    }

    .layer-miniature {
        min-width: 12px;
    }
}