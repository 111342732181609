.switcher-bar {
    display: flex;
    position: absolute;
    bottom: 76px;
    right: 70px;
}

.switcher-bar > div {
    width: 60px;
    height: 60px;
    margin-left: 6px;
    background: lightgray;
    /* box-shadow: 0px 2px 5px rgba(250,250,250, 0.25); */
    border: 1px solid gray;
    border-radius: 4px;
    overflow: hidden;

    transition: all .3s;
}

.switcher-bar > div img {
    width: 100%;
    height: 100%;
}

.switcher-bar > div:hover {
    /* border: 2px solid white; */
    box-shadow: 0px 2px 7px rgba(250,250,250, 0.6);
    cursor: pointer;
}

.switcher-bar > div.selected {
    border: 2px solid white;
}

@media (min-width: 320px) and (max-width: 480px) {
    .switcher-bar {
        display: none;
    }
}